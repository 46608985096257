<template>
  <div id="admin_order" >
    <div class="div_title">
      <span style="color: #ff7f50;">주문</span>
      <span>
        <input class="button" type="button" v-on:click="click({type:'SELECT_ORDER_REGIST'})" style="margin-top:-5px;margin-right:5px;" value="신청서" >
        <input class="button" type="button" v-on:click="click({type:'search'})" style="margin-top:-5px;margin-right:5px;" value="조회" >
        <label ref='lbl_mod' class="condition-admin" for="chk_mod" style="float:right;" >변경건</label>
        <input ref='chk_mod' id="chk_mod" type="checkbox" style="max-width:10px;float:right;" @change="click({type:'searchOpt', val:'mod'})"/>
        <label ref='lbl_chk_receive' class="condition-admin" for="chk_receive" style="float:right;" >도착확인</label>
        <input ref='chk_receive' id="chk_receive" type="checkbox" style="max-width:10px;float:right;" @change="click({type:'searchOpt', val:'receive'})"/>
      </span>
    </div>
    <div class="card main " style="margin-top: 20px;">
      <table style="width:100%;">
        <tr style="width:100%;">
          <td style="max-width: 90px; min-width: 90px; margin-right:10px;">
            <datepicker v-model="pickDateStart" style="max-width:90px"/>
          </td>
          <td style="text-align: center;">&#126;</td>
          <td style="max-width: 90px; min-width: 90px;">
            <datepicker v-model="pickDateEnd" style="max-width:90px"/>
          </td>
          <td style="width:100%">
            <input ref="conditionValue" type="text" v-on:keyup.enter="click({type:'search'})" style="max-width:60px; padding: 5px; float:right;" placeholder="검색어" >
            <select class="select-contract" style="margin-top: 0px;max-width: 120px;" v-model="condition" > 
              <option value = "" >전체</option>
              <option v-for="(condition, index) in conditionList" :value="condition" v-bind:key="index">{{condition}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <Tag severity="danger" class="small-btn" value="오늘" style="cursor:pointer;" @click="click({type:'range', value:0})"/>
            <Tag severity="danger" class="small-btn" value="이번달" style="cursor:pointer;" @click="click({type:'range', value:1})"/>
            <Tag severity="danger" class="small-btn" value="한달" style="cursor:pointer;" @click="click({type:'range', value:30})"/>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="orderList.length === 0" class="card main no-result" style="margin-top: 40px;margin-bottom: 40px;"> 
      조회 내역이 없습니다.
    </div>
    <div v-else>
      <DataTable v-model:expandedRows="expandedRows" v-model:selection="orderList" :value="orderList" dataKey="IDX" @rowExpand="onRowExpand" >
        <Column expander style="max-width:10px;background-color: white;color:black;"  />
        <Column header="구매자" style="background-color: white;color:black;min-width:80px;">
          <template #body="slotProps">
            {{ slotProps.data.ORDER_NAME }}
            <br>{{ slotProps.data.IDX }}
          </template>
        </Column>
        <Column header="등록일" style="background-color: white;color:black;">
          <template #body="slotProps">
            {{ this.api.getDateByFormat(slotProps.data.ORDER_DATE, 'YYYY.MM.DD') }}
          </template>
        </Column>
        <Column field="NAME" header="상품명" style="background-color: white;color:black;"></Column>
        <Column header="비고" style="background-color: white;color:black;min-width:90px;">
          <template #body="slotProps">
            <div style="text-align:center;">
              {{ this.api.getOrderStatus(Number(slotProps.data.STATUS)) }}<br>
              {{ slotProps.data.COUNTRY }}
            </div>
          </template>
        </Column>
        <template #expansion="slotProps" >
          <adminorderc @click="click" :id="slotProps.data.ID" :msg="slotProps.data.MSG" :idx="slotProps.data.IDX" :uid="slotProps.data.UID" :notice="slotProps.data.NOTICE" :deliveryKrCompany="slotProps.data.DELIVERY_KR_COMPANY" :deliveryForeignCompany="slotProps.data.DELIVERY_FOREIGN_COMPANY" :clearanceNumber="slotProps.data.CLEARANCE_NUMBER" :invoiceNumber="slotProps.data.INVOICE_NUMBER" :trackingNumber="slotProps.data.TRACKING_NUMBER" :orderNumber="slotProps.data.ORDER_NUMBER" :orderType="slotProps.data.ORDER_TYPE" :mail="slotProps.data.MAIL" :addrDetail="slotProps.data.ADDR_DETAIL" :addrRoad="slotProps.data.ADDR_ROAD" :addrZip="slotProps.data.ADDR_ZIP" :clearance="slotProps.data.CLEARANCE" :orderName="slotProps.data.ORDER_NAME" :status="slotProps.data.STATUS" :phone="slotProps.data.PHONE" :url="slotProps.data.URL" :amount="slotProps.data.AMOUNT" :name="slotProps.data.NAME" :brand="slotProps.data.BRAND" :deliveryPrice2="slotProps.data.DELIVERY_PRICE2" :deliveryPrice1="slotProps.data.DELIVERY_PRICE1" :progress="slotProps.data.PROGRESS" :sellPrice="slotProps.data.SELL_PRICE" :originPrice="slotProps.data.ORIGIN_PRICE" :country="slotProps.data.COUNTRY" :orderDate="slotProps.data.ORDER_DATE" :payPrice="slotProps.data.PAY_PRICE" :imgUrl="slotProps.data.IMG_URL" :fileName="slotProps.data.FILE_NAME" />
        </template>
      </DataTable>
      <br>
      <div style="text-align: center; margin: 20px;">
        <label @click="paging('prev')" class="link">
          이전
        </label>
        <span style="margin:20px">{{page}}</span>
        <label @click="paging('next')" class="link">
          다음
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import datepicker from 'vue3-datepicker' 
import { ref } from 'vue'
import adminorderc from '@/components/content/adminorder_content.vue'

export default {
  setup() {
    const expandedRows = ref({})
    function onRowExpand (event) {
      expandedRows.value = {[event.data.IDX]:true}
    }
    return {
      onRowExpand, expandedRows
    }
  },
  components: {
    datepicker, adminorderc
  },  
  mounted() {
  }, 
  data() { 
    return { 
      condition:"",
      page:1,
      pickDateStart:ref(new Date()),
      pickDateEnd:ref(new Date()),
      orderList: ref([]),
      orderedMap: new Map(),
      adminordercKey:-1,
      conditionList: ['FORWARDER', 'ORDER_NAME','URL','NAME','IDX','COUNTRY','ORDER_NUMBER','TRACKING_NUMBER','INVOICE_NUMBER','STATUS','NOTICE','BRAND','CLEARANCE','PHONE','MAIL','ADDR_ROAD','ADDR_DETAIL']
    } 
  },
  methods: {
    async click(param) {
      const type = param.type
      if(type === 'search') this.search()
      else if(type === 'modify-item') {
        const json = param.value
        await this.api.axiosGql('UPDATE_ORDER_ITEM', {type:'UPDATE_ORDER_ITEM', id:json.id, orderName:json.orderName, phone:json.phone, clearance:json.clearance, addrZip:json.addrZip, addrRoad:json.addrRoad, addrDetail:json.addrDetail, idx:Number(json.idx), progress:json.progress, status:Number(json.status), notice:json.notice, msg:json.msg, orderNumber:json.orderNumber, deliveryForeignCompany:json.deliveryForeignCompany, clearanceNumber:json.clearanceNumber, invoiceNumber:json.invoiceNumber, trackingNumber:json.trackingNumber, orderType:json.orderType, url:json.url, amount:Number(json.amount), name:json.name, brand:json.brand, deliveryPrice2:Number(json.deliveryPrice2), deliveryPrice1:Number(json.deliveryPrice1), sellPrice:Number(json.sellPrice), originPrice:json.originPrice, payPrice:Number(json.payPrice), imgUrl:json.imgUrl, mail:json.mail
       })
        .then(async (res) => {
          if(res.status === 200) {
            this.$notify('수정되었습니다')
            this.orderList = Array()
            this.search()
          }else this.$notify('다시 시도해주세요')
        }).catch((err) => {
          this.$notify('다시 시도해주세요')
          console.log(err)
        })
      } else if(type === 'modify-order') {
        const json = param.value
        await this.api.axiosGql('UPDATE_ORDER_INFO_USER', {type:'UPDATE_ORDER_INFO_USER', uid:json.uid, orderName:json.orderName, phone:json.phone, clearance:json.clearance, addrRoad:json.addrRoad, addrDetail:json.addrDetail, addrZip:json.addrZip, mail:json.mail})
        .then(async (res) => {
          if(res.status === 200) {
            this.emitter.emit('confirm', {message: '수정되었습니다.', header:'비용상세', icon:'pi pi-info-circle'})
          } else this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:'비용상세', icon:'pi pi-exclamation-triangle'})
        }).catch((err) => {
          this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:'비용상세', icon:'pi pi-exclamation-triangle'})
          console.log(err)
        })
      } else if(type === 'UPSERT_ORDER_PRICE_DTL') {
        if(confirm("수정할까요?")) {
          const json = param.value
          await this.api.axiosGql(type, json)
          .then(async (res) => {
            if(res.status === 200) {
              this.$notify('수정되었습니다')
              this.orderList = Array()
              this.search()
            }else this.$notify('다시 시도해주세요')
          }).catch((err) => {
            this.$notify('다시 시도해주세요')
            console.log(err)
          })
        }
      } else if(type === 'range') {
        const val = param.value
        this.pickDateEnd = ref(new Date())
        if(val === 0) this.pickDateStart = ref(new Date())
        else if(val === 1) {
          let firstDay = new Date(this.pickDateEnd.getFullYear(), this.pickDateEnd.getMonth(), val);
          this.pickDateStart = firstDay
        } else if(val === 30) {
          let before30days = new Date(this.pickDateEnd.getFullYear(), this.pickDateEnd.getMonth(), this.pickDateEnd.getDate() - val);
          this.pickDateStart = before30days
        }
      } else if(type === 'SELECT_ORDER_REGIST') {
        await this.api.axiosGql(type, {type:type, id:'' })
        .then( async (res) => {
          let orders = res.data.getOrderRegist
          if(orders.length === 0) {
            alert('내역 없습니다.')
          } else {
            // TODO 신청서 다운로드(GEEK9_local에서만 동작 중인 기능.. 웹에서도 되어야 한다)
            // console.log()
            // const workbook = xlsx.readFile(filePath.default, {type:'file'})
            // const sheetName = workbook.SheetNames[0]
            // const worksheet = workbook.Sheets[sheetName]

            // for(let i = 0; i < orders.length; i++) {
            //   const order = orders[i]
            //   // const orderList = order.order
            //   const orderInfo = order.orderInfo
            //   const newRow = i+3
            //   worksheet[`${String.fromCharCode(65 + i)}${newRow}`] = { t: 's', v: orderInfo.ORDER_NAME }
            //   // console.log(orderList)
            //   // console.log(orderInfo)
            // }

            // xlsx.writeFile(workbook, 'express_form_geek9.xlsx');
          }
        })
      } else if(type === 'searchOpt') {
        const val = param.val
        if(val === 'mod' && this.$refs.chk_mod.checked === true) {
          this.$refs.chk_receive.checked = false
        } else if(this.$refs.chk_receive.checked === true) {
          this.$refs.chk_mod.checked = false
        }
      }
    },
    async search() {
      const pickDateStart = new Date(this.pickDateStart)
      const pickDateEnd = new Date(this.pickDateEnd)
      const dateStart = new Date(pickDateStart.getTime() - (pickDateStart.getTimezoneOffset() * 60000)).toISOString().slice(0,10).replace(/-/g,"")
      const dateEnd = new Date(pickDateEnd.getTime() - (pickDateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0,10).replace(/-/g,"")
      const condition = this.condition
      const conditionValue = this.$refs.conditionValue.value
      const chkReceive = this.$refs.chk_receive.checked === true ? 1 : 0
      if(condition !== '' && conditionValue === '' && condition !== 'FORWARDER') this.$notify('검색어를 입력하세요')
      else {
        const isMod = this.$refs.chk_mod.checked
        const idxList = []
        if(isMod) {
          await this.api.axiosGql('SELECT_MOD_ORDER_HISTORY', {type:'SELECT_MOD_ORDER_HISTORY', })
          .then( async (res) => {
            let json = res.data
            if(json.length === 0) {
              this.$notify('데이터가 없습니다.')
            } else {
              for(let i = 0; i < json.length; i++) {
                const idx = String(json[i].CONTENT).split('-')[1]
                if(idxList.indexOf(idx) === -1) idxList.push(idx)
              }
            }
          })
        } 
        await this.api.axiosGql('SELECT_ORDER_BY_CONDITION', {type: 'SELECT_ORDER_BY_CONDITION', list:idxList, page:this.page, startDate:dateStart, endDate:dateEnd, condition:condition, conditionValue:conditionValue, chkReceive:chkReceive})    
        .then( async (res) => {
          const json = res.data
          if(json === null || json === 'null' || json === undefined || json.length === 0) {
            this.$notify('데이터가 없습니다.')
            if(this.page === 1) {
              this.orderList = Array()
              this.totalCnt = 0
              this.orderedMap.clear()
            } else this.page -= 1
          } else this.orderList = json
          this.adminordercKey++
        })
      }
    },
    paging(type) {
      // this.collapseAll()
      if(type === 'prev') {
        if(this.page === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      this.search()
    },
    // collapse(param) {
    //   const sub_idx = param.idx
    //   const sub_menu_list = document.getElementById("admin_order")
    //   let col_btn_list = sub_menu_list.getElementsByClassName("listbutton")
    //   let col_con_list = sub_menu_list.getElementsByClassName("content")
    //   const btn = col_btn_list[sub_idx]
    //   const con = col_con_list[sub_idx]
      
    //   if(con === undefined) {
    //     this.collapseAll()
    //     this.paging('next')
    //     const el = document.getElementById('admin_order')
    //     el.scrollIntoView()
    //   } else {
    //     // open
    //     const btnClass = btn.getAttribute("class") 
    //     if(btnClass === 'collapsible select listbutton' || btnClass === 'collapsible select text-cut listbutton') {
    //       btn.setAttribute("class", "collapsible text-cut listbutton")
    //       btn.style.color = "black"
    //       btn.style.fontWeight = ""
    //       con.style.maxHeight = null
    //     } else {    // close
    //       this.collapseAll()
    //       btn.setAttribute("class", "collapsible select text-cut listbutton")
    //       btn.style.fontWeight = "bold"
    //       con.style.maxHeight = con.scrollHeight + "px"
    //       // this.emitter.emit('orderRefresh', {idx:param.orderIdx, uid:param.uid})
    //     }
    //   }
    // },    
    // collapseAll() {
    //   const sub_menu_list = document.getElementById("admin_order")
    //   if(sub_menu_list !== null && sub_menu_list !== undefined) {
    //     let col_btn_list = sub_menu_list.getElementsByClassName("listbutton")
    //     let col_con_list = sub_menu_list.getElementsByClassName("content")
        
    //     for(var i = 0; i < col_btn_list.length; i++) {
    //       let colBtn = col_btn_list[i]
    //       if(colBtn !== undefined) {
    //         colBtn.setAttribute("class", "collapsible text-cut listbutton")
    //         colBtn.style.color = "black"
    //         colBtn.style.fontWeight = ""
    //       } 
    //       let tmpCon = col_con_list[i]
    //       if(tmpCon !== undefined ) tmpCon.style.maxHeight = null
    //     }
    //   }
    // }, 
  }
}
</script>

<style scoped>
/* .button {
  float:right;
  background-color: lightsalmon;  
  color: white;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  padding:5px;
  max-width: 50px;
  cursor: pointer;
} */
.small-btn {
  width:30%;
  font-size:11px;
  margin-left: 2px;
  margin-right: 2px;
}
/* .collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: rgb(250, 250, 250);
}
.tab button:hover {
  background-color: #ddd;
}
.tab button.active {
  background-color: #ff7f50;
}
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ff7f50;
  border-top: none;
}
.v3dp__input_wrapper > input {
  padding-top:5px;
  padding-bottom:5px; 
  max-width:100px;
  text-align: center;
} */
</style>